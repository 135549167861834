import Axios from "axios";
let token = window.localStorage.getItem("accessToken") || "";

const axios = Axios.create({
  baseURL: `https://api.eylo.co.uk/api/v1`,

  // baseURL: `http://192.168.0.242:7141/api/v1`,

  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export default axios;
